<template>
  <div class="public-get-full-info">
    <div class="public-get-full-info-cup">
      <vz-icon
        url="/svg/vz-cup.svg"
        variant="white"
        size="56"
        no-masked-icon
      />
      Получите полную информацию о преимуществах сотрудничества с Vozovoz
    </div>

    <vz-button
      class="public-get-full-info-button vz-cursor-pointer"
      variant="public-primary"
      large
      @click="offer"
    >
      {{ buttonText }}
    </vz-button>
    <vz-feedback-message-modal
      ref="feedbackMessage"
      name="feedback-full-info"
      :department-code="departmentCode"
    />
  </div>
</template>

<script setup lang="ts">
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';
import VzFeedbackMessageModal from '~/components/modals/vz-feedback-message-modal.vue';

const props = defineProps({
  departmentCode: {
    type: String,
    default: 'becomeAClient'
  },
  buttonText: {
    type: String,
    default: 'Коммерческое предложение'
  },
})

const feedbackMessage = ref()

function offer() {
  feedbackMessage.value?.show()
}
</script>

<style lang="scss" scoped>
.public-get-full-info {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 28px 40px;
  background-color: $primary;
  color: white;

  &-cup {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: $fs-24;
    line-height: 32px;
  }

  &-button {
    min-height: 56px;
    min-width: 429px;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: $pub-md) {
    &-cup {
      font-size: $fs-22;
    }

    &-button {
      min-width: 339px;
    }
  }

  @media screen and (max-width: $pub-sm) {
    flex-wrap: wrap;

    &-button {
      min-width: 100%;
    }
  }

  @media screen and (max-width: $pub-xs) {
    &-cup {
      align-items: start;
      font-size: $fs-20;
    }
  }

  @media screen and (max-width: $xs) {
    &-cup {
      flex-wrap: wrap;
    }
  }
}
</style>